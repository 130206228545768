import { useContext } from 'react';
import SubmitAttempt from '../utils/utility/context';
import { Regex_Pattern } from '../utils/types/models';
import { ContactInformationObject } from '../utils/types/models';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { MaxLengthMapper } from '../utils/types/enums';
import { Sanitize } from '../utils/utility/sanitize';
import { useForm } from "react-hook-form";

export default function ContactDetails({
  currentUser,
  contactDetailsObject,
  countries
}) {
  const { submitAttempted } = useContext(SubmitAttempt);
  const { setSubmitAttempted } = useContext(SubmitAttempt);
  const {
    register,
    getValues,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: currentUser?.contactInformation.email || '',
      companyName: currentUser?.contactInformation?.companyName || null,
      financeEmail: currentUser?.contactInformation?.financeEmail || null,
      phone: currentUser?.contactInformation?.phone || null,
      mobile: currentUser?.contactInformation?.mobile || null,
      skype: currentUser?.contactInformation?.skype || null,
      addressLine1: currentUser?.contactInformation?.address_line_one || null,
      addressLine2: currentUser?.contactInformation?.address_line_two || null,
      city: currentUser?.contactInformation?.city || null,
      countryCode: currentUser?.contactInformation?.iso_country_code || null,
      postalCode: currentUser?.contactInformation?.post_code || null,
      additionalInformation: currentUser?.contactInformation?.additionalInformation || null,
      taxVAT: currentUser?.contactInformation?.vat || null,
      registrationNumber: currentUser?.contactInformation?.registrationNumber || null,
    },
  });

  function createDropdownMenuItems(fullArray) {
    let result = [];
    for (let index = 0; index < fullArray.length; index++) {
      let selectItem = { label: fullArray[index].name, alpha2: fullArray[index].alpha2 };
      result.push(selectItem)
    }
    return result;
  }

  contactDetailsObject.current = ContactInformationObject(
    watch('companyName'),
    watch('email'),
    watch('financeEmail'),
    watch('phone'),
    watch('mobile'),
    watch('skype'),
    watch('addressLine1'),
    watch('addressLine2'),
    watch('city'),
    watch('countryCode'),
    watch('postalCode'),
    watch('additionalInformation'),
    watch('taxVAT'),
    watch('registrationNumber')
  );

  countries = countries.filter(country => country.enabled === true && country.alpha2 !== 'ZZ');
  const countriesSelectItem = createDropdownMenuItems(countries);

  return (
    <>
      <div className='p-d-flex p-col p-fluid p-ai-center'>
        <div className='p-d-flex'>
          <div className='p-fluid p-col-6 p-grid nested-grid p-pl-4 p-pr-4 p-as-start'>
            <div className='p-field p-col-12'>
              <label>Company Name (Entity to Invoice) *</label>
              <InputText
                type='text'
                className={
                  (submitAttempted && !getValues('companyName')) || errors?.companyName?.message
                    ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
                    : 'p-inputtext-lg p-d-block'
                }
                {...register("companyName", {
                  required: "Company Name is required",
                  onBlur: (event) =>
                    setValue(
                      "companyName",
                      Sanitize.sanitizeCompanyName(event.target.value)
                    ),
                  onChange: (event) => {
                    const company = (event.target.value).trim();
                    if (company.length > MaxLengthMapper.companyMaxLength) {
                      setError('companyName', { message: 'You have exceeded the character limit.' });
                    } else if (!Regex_Pattern.company.test(event.target.value) && company) {
                      setError('companyName', { message: 'Invalid Company name' });
                    } else if (Sanitize.countSpace(event.target.value) > 5) {
                      setError('companyName', { message: 'Only five spaces are allowed! Please remove extra spaces.' });
                    } else if (!event.target.value) {
                      setError('companyName', { message: 'Please enter a company name' });
                    } else {
                      setError('companyName', {})
                    }
                  },
                })}
              />
              {errors?.companyName?.message &&
                <small className='p-error validation-error'>{errors?.companyName?.message}</small>
              }
            </div>
            <div className='p-field p-col-12'>
              <label>Address Line 1 *</label>
              <InputText
                type='text'
                className={
                  (submitAttempted && !getValues('addressLine1')) || errors?.addressLine1?.message
                    ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
                    : ' p-inputtext-lg p-d-block '
                }
                {...register("addressLine1", {
                  required: "This field is required",
                  onChange: (event) => {
                    const _addressLine2 = getValues('addressLine2')
                    setValue('addressLine1', Sanitize.sanitizeAddress(event.target.value));
                    if (event.target.value.length > MaxLengthMapper.addressMaxLength) {
                      setError('addressLine1', { message: 'You have exceeded the character limit.' });
                    } else if (_addressLine2 && (event.target.value === _addressLine2)) {
                      setError('addressLine1', { message: 'Address Line 1 cannot be same as Address Line 2' });
                    } else {
                      setError('addressLine1', { message: '' });
                      setError('addressLine2', { message: '' });
                    }
                  },
                })}
              />
              {errors?.addressLine1?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.addressLine1?.message}</small>
              }
            </div>
            <div className='p-field p-col-12'>
              <label>Address Line 2</label>
              <InputText
                className={errors?.addressLine2?.message ? 'p-invalid p-inputtext-lg p-d-block input-w-validation' : 'p-inputtext-lg p-d-block'}
                type='text'
                {...register("addressLine2", {
                  onChange: (event) => {
                    const _addressLine1 = getValues('addressLine2')
                    setValue('addressLine2', Sanitize.sanitizeAddress(event.target.value));
                    if (event.target.value.length > MaxLengthMapper.addressMaxLength) {
                      setError('addressLine2', { message: 'You have exceeded the character  limit.' });
                    } else if (_addressLine1 && (event.target.value === _addressLine1)) {
                      setError('addressLine2', { message: 'Address Line 2 cannot be same as Address Line 1' });
                    } else {
                      setError('addressLine1', { message: '' });
                      setError('addressLine2', { message: '' });
                    }
                  },
                })}
              />
              {errors?.addressLine2?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.addressLine2?.message}</small>
              }
            </div>
            <div className='p-field p-col-6'>
              <label>City *</label>
              <InputText
                className={
                  (submitAttempted && !getValues('city')) || errors?.city?.message
                    ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
                    : ' p-inputtext-lg p-d-block '
                }
                type='text'
                {...register("city", {
                  required: 'This field is required',
                  onChange: (event) => {
                    setValue('city', Sanitize.sanitizeCity(event.target.value));
                    if (event.target.value.length > MaxLengthMapper.cityMaxLength) {
                      setError('city', { message: 'You have exceeded the character  limit.' });
                    } else if (Sanitize.countSpace(event.target.value) > 2) {
                      setError('city', { message: 'Only two spaces are allowed! Please remove extra spaces.' });
                    } else {
                      setError('city', { message: '' });
                    }
                  },
                })}
              />
              {errors?.city?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.city?.message}</small>
              }
            </div>

            <div className='p-field p-col-6'>
              <label>Country *</label>
              <Dropdown
                className={
                  submitAttempted && !getValues('countryCode')
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('countryCode')}
                options={countriesSelectItem}
                optionLabel="label"
                optionValue="alpha2"
                {...register('countryCode', {
                  required: 'This field is required',
                  onChange: (event) => setValue('countryCode', event.value)
                })}
                filter
              />
            </div>

            <div className='p-field p-col-6'>
              <label>Postal Code</label>
              <InputText
                className={errors?.postalCode?.message
                    ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
                    : ' p-inputtext-lg p-d-block '
                }
                {...register('postalCode', {
                  onChange: (event) => {
                    setValue('postalCode', Sanitize.sanitizePostalCode(event.target.value));
                    if (event.target.value.length > MaxLengthMapper.postCodeMaxLength) {
                      setError('postalCode', { message: 'You have exceeded the character limit!' });
                    } else {
                      setError('postalCode', { message: '' });
                    }
                  }
                })}
              />
              {errors?.postalCode?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.postalCode?.message}</small>
              }
            </div>
          </div>

          <div className='p-fluid p-col-6 p-grid nested-grid p-pl-4 p-pr-4 p-as-start'>
            <div className='p-field p-col-12'>
              <label>VAT Number</label>
              <InputText
                className='p-inputtext-lg p-d-block'
                type='text'
                {...register('taxVAT', {
                  onChange: (event) => {
                    setValue('taxVAT', Sanitize.removeSpecialCharacters(event.target.value));
                    if ((event.target.value).length > MaxLengthMapper.registrationMaxLength) {
                      setError('taxVAT', { message: 'You have exceeded the character limit.' });
                    } else {
                      setError('taxVAT', { message: '' });
                    }
                  }
                })}
              />
              {errors?.taxVAT?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.taxVAT?.message}</small>
              }
            </div>

            <div className='p-field p-col-12'>
              <label>Registration Number</label>
              <InputText
                className='p-inputtext-lg p-d-block'
                type='text'
                {...register('registrationNumber', {
                  onChange: (event) => {
                    setValue('registrationNumber', Sanitize.removeSpecialCharacters(event.target.value));
                    if ((event.target.value).length > MaxLengthMapper.vatMaxLength) {
                      setError('registrationNumber', { message: 'You have exceeded the character limit.' });
                    } else {
                      setError('registrationNumber', { message: '' });
                    }
                  }
                })}
              />
              {errors?.registrationNumber?.message &&
                <small className='p-error p-d-block validation-error'>{errors?.registrationNumber?.message}</small>
              }
            </div>

            <div className='p-field p-col-12'>
              <label>Email *</label>
              < InputText
                className={
                  errors?.email?.message || (submitAttempted && !getValues('email')) ?
                    'p-invalid p-inputtext-lg p-d-block input-w-validation' :
                    ' p-inputtext-lg p-d-block e-disabled'
                }
                disabled={true}
                type='text'
                {...register('email', {
                  onChange: (event) => {
                    if (!Regex_Pattern.email.test(event.target.value)) {
                      setError('email', { message: 'Please enter a valid email' });
                    } else {
                      setError('email', { message: '' })
                    }
                  }
                })}
              />
              {errors?.email?.message && <small className='p-error p-d-block validation-error'>
                {errors?.email?.message}
              </small>}
            </div>

            <div className='p-field p-col-12'>
              <label>Finance Email *</label>
              <InputText
                className={
                  errors?.financeEmail?.message || (submitAttempted && !getValues('financeEmail'))
                    ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
                    : ' p-inputtext-lg p-d-block '
                }
                type='text'
                {...register('financeEmail', {
                  register: 'This field is required',
                  onChange: (event) => {
                    if (!Regex_Pattern.financeEmail.test(event.target.value)) {
                      setError('financeEmail', { message: 'Invalid Email! (Only one @ and dot as special characters are allowed in the e-mail address)' });
                    } else if ((event.target.value).length > MaxLengthMapper.emailMaxLength) {
                      setError('financeEmail', { message: 'You have exceeded the character limit.' });
                    } else {
                      setError('financeEmail', { message: '' })
                    }
                  }
                })}
              />
              {errors?.financeEmail?.message && <small className='p-error p-d-block validation-error'>
                {errors?.financeEmail?.message}
              </small>}
            </div>

            <div className='p-field p-col-6'>
              <label>Skype</label>
              <InputText
                className='p-inputtext-lg p-d-block'
                type='text'
                {...register('skype', {
                  onChange: (event) => {
                    setValue('skype', Sanitize.sanitizeSkype(event.target.value));
                    if ((event.target.value).length > MaxLengthMapper.skypeMaxLength) {
                      setError('skype', { message: 'You have exceeded the character limit.' });
                    } else {
                      setError('skype', { message: '' });
                    }
                  }
                })}
              />
              {errors?.skype?.message && <small className='p-error p-d-block validation-error'>
                {errors?.skype?.message}
              </small>}
            </div>

            <div className='p-field p-col-6'>
              <label>Mobile </label>
              <InputText
                maxLength={13}
                type='text'
                className='p-inputtext-lg p-d-block'
                {...register('mobile', {
                  onChange: (event) => {
                    setValue('mobile', Sanitize.sanitizePhone(event.target.value));
                    if (event.target.value === '') {
                      setError('mobile', { message: '' });
                    }
                    if (!Regex_Pattern.phone_number.test(event.target.value)) {
                      setError('mobile', {
                        message: 'Please, use the correct format of mobile number, i.e +31, +44.., etc.'
                      });
                    } else {
                      setError('mobile', { message: '' });
                    }
                  },
                  onBlur: (event) => {
                    const name = (event.target.value).trim();
                    setValue('mobile', Sanitize.sanitizePhone(name));
                    if (event.target.value === '') {
                      setError('mobile', { message: '' });
                    }
                    if (!Regex_Pattern.phone_number.test(event.target.value) && event.target.value !== '') {
                      setError('mobile', {
                        message: 'Please, use the correct format of mobile number, i.e +31, +44.., etc.'
                      });
                    } else {
                      setError('mobile', { message: '' });
                    }
                  }
                })}
              />
              {errors?.mobile?.message && <small className='p-error p-d-block validation-error'>
                {errors?.mobile?.message}
              </small>}
            </div>

            <div className='p-field p-col-6'>
              <label>Phone </label>
              <InputText
                className='p-inputtext-lg p-d-block'
                maxLength={13}
                type='text'
                {...register('phone', {
                  onChange: (event) => {
                    setValue('phone', Sanitize.sanitizePhone(event.target.value));
                    if (event.target.value === '') {
                      setError('phone', { message: '' });
                    }
                    if (!Regex_Pattern.phone_number.test(event.target.value)) {
                      setError('phone', {
                        message: 'Please, use the correct format of mobile number, i.e +31, +44.., etc.'
                      });
                    } else {
                      setError('phone', { message: '' });
                    }
                  },
                  onBlur: (event) => {
                    const name = (event.target.value).trim();
                    setValue('phone', Sanitize.sanitizePhone(name));
                    if (event.target.value === '') {
                      setError('phone', { message: '' });
                    }
                    if (!Regex_Pattern.phone_number.test(event.target.value) && event.target.value !== '') {
                      setError('phone', {
                        message: 'Please, use the correct format of mobile number, i.e +31, +44.., etc.'
                      });
                    } else {
                      setError('phone', { message: '' });
                    }
                  }
                })}
              />
              {errors?.phone?.message && <small className='p-error p-d-block validation-error'>
                {errors?.phone?.message}
              </small>}
            </div>
            <div className='p-field p-col-12'>
              <label>Additional Information</label>
              <InputTextarea
                rows={5}
                cols={30}
                {...register('additionalInformation', {
                  onKeyUp: (event) => {
                    if ((event.target.value).length > MaxLengthMapper.AdditionalInformationMaxLength) {
                      setError('additionalInformation', {
                        message: 'You have exceeded the character limit.'
                      });
                    } else {
                      setError('additionalInformation', { message: '' });
                    }
                  }
                })}
              />
              <div>
                {errors?.additionalInformation?.message &&
                  <small className='p-error validation-error'>
                    {errors?.additionalInformation?.message}
                  </small>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
