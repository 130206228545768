export const Regex_Pattern = {
  email: /^\w+([\.-]?\w+)*@\w+([\.\-\_]?\w+)*(\.\w{2,14})+$/,
  prev_email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  url:/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  iso_country_code: /^[A-Z]{2}$/,
  websiteUrl: /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/)?$/,
  name: /[^a-zA-Z-\s]/gi,
  title: /[^a-zA-Z\s]/gi,
  remove_double_chars: /[\s]+/g,
  phone_number: /^[+]{1}[0-9]{1,3}[0-9]{1,14}$/,
  allSpecialCharacters: /[^a-zA-Z0-9\s]/gi,
  websiteSpecialCharacters: /[^a-zA-Z0-9:/.()-]/g,
  vatAndRegistration: /^[a-zA-Z0-9]*$/,
  // RemoveCharacterVatRegsitration: /[^a-zA-Z0-9\s]/gi,
  addressLine2: /^[a-zA-Z0-9\s]*$/,
  removeMultipleSpaces: /[\s\s]+/gi,
  brand_name: /^(?!.*--)(?!.*!!)(?!.*__)(?!.*@@)[a-zA-Z0-9.\s][a-zA-Z0-9.\s@_-\s]{0,500}(?![^@_.-])$/,
  remove_special_caracter : /[^a-zA-Z0-9.@_!-\s]/gi, 
  remove_extra_spaces: /[\s]+/g,
  checkUppercaseLetter: /[A-Z]/,
  phoneSpecialCharacters: /[^0-9\+]/gi,
  registrationNumber: /[^a-zA-Z0-9]/gi,
  post_code: /^[0-9A-Z\s]/,
  company: /^(?!-)[A-Za-z0-9-]( ?[A-Za-z0-9-] ?)*$/,
  city: /^(?!.* {2})[A-Z]{1}[a-z\s]*$/,
  address_LineOne: /^((?!.* {2})([a-zA-Z0-9\s])){0,150}$/,
  companySpecialChars: /[^a-zA-Z0-9\s\-]/gi,
  postalCodeSpecialChars: /[^A-Z0-9\s]/gi,
  financeEmail: /^\w+([\.-]?\w+)*@\w+([\.\-\_]?\w+)*(\.\w{2,14})+$/,
  singleDot: /^\d+(?:\.\d+)?$/,
  removeNonNumeric: /[^0-9,]/g,
  paymentOptions: /^(0|[1-9][0-9]*)-(0|[1-9][0-9]*)\s(h|d|w|m|min)$/i,
  convertPaymentOptionsString: /(\d+)\s*\w+?\s*(\d+)/gi,
  
};

export const LeadDetailsObject = (firstName, lastName, jobTitle) => {
  return { firstName: firstName, lastName: lastName, title: jobTitle };
};

export const ContactInformationObject = (
  companyName,
  email,
  financeEmail,
  phone,
  mobile,
  skype,
  addressLine1,
  addressLine2,
  city,
  countryCode,
  postalCode,
  additionalInformation,
  taxVAT,
  registrationNumber
) => {
  return {
    email: email,
    financeEmail: financeEmail,
    skype: skype,
    phone: phone,
    mobile: mobile,
    companyName: companyName,
    additionalInformation: additionalInformation,
    address_line_one: addressLine1,
    address_line_two: addressLine2,
    city: city,
    post_code: postalCode,
    iso_country_code: countryCode,
    vat: taxVAT,
    registrationNumber: registrationNumber    
  };
};

export const BrandInformationObject = (
  brandName,
  website,
  affiliatePlatformURL
) => {
  return {
    name: brandName,
    website: website,
    affiliatePlatformUrl: affiliatePlatformURL,
  };
};

export const BrandDetailsObject = (
  yearLaunched,
  availableMarkets,
  excludedMarkets,
  selectedLicences,
  gamesProviderSports,
  gamesProviderCasino,
  selectedGames,
  additionalBrandInformation,
  gameCount,
  vipProgram,
  liveDealer,
  mobileVersion,
  macVersion,
  ecograApproved,
  selectedLanguages,
  selectedProduct,
) => {
  return {
    yearLaunched: yearLaunched,
    availableMarkets: availableMarkets,
    excludedMarkets: excludedMarkets,
    licences: selectedLicences,
    gamesProviderSports: gamesProviderSports,
    gamesProviderCasino: gamesProviderCasino,
    games: selectedGames,
    additionalBrandinformation: additionalBrandInformation,
    gameCount: gameCount,
    vipProgram: vipProgram,
    liveDealer: liveDealer,
    mobileVersion: mobileVersion,
    macVersion: macVersion,
    ecograApproved: ecograApproved,
    languagesAvailable: selectedLanguages,
    product: selectedProduct
  };
};

export const BankingDetailsObject = (
  depositMethods,
  withdrawalMethods,
  creditCards,
  eWallets,
  bankTransfers,
  cheques,
  bitcoins,
  documentValidation,
  selectedCurrencies,
  currenciesDetails
) => {
  return {
    paymentMethodsDeposit: depositMethods,
    paymentMethodsWithdrawal: withdrawalMethods,
    creditCardsNumberOfDays: creditCards,
    eWalletNumberOfDays: eWallets,
    bankTransfersNumberOfDays: bankTransfers,
    chequeNumberOfDays: cheques,
    bitcoinNumberOfDays: bitcoins,
    documentValidationNumberOfDays: documentValidation,
    currencies: selectedCurrencies,
    currencyDetails: currenciesDetails,
  };
};

export const LeadObject = (
  id,
  leadDetailsObject,
  contactDetailsObject,
  brandInformationObject,
  brandDetailsMergedObject,
  status,
  last_modified_by
) => {
  return {
    id: id,
    leadDetails: leadDetailsObject,
    contactInformation: contactDetailsObject,
    brandInformation: brandInformationObject,
    brandDetails: brandDetailsMergedObject,
    status: status,
    last_modified_by: last_modified_by
  };
};

export const inputAttributes = {
  nameMaxLength: 50,
  titleMaxLength: 100,
  currencyMaxLength: 10,
}